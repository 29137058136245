import { Component, Input, OnInit } from '@angular/core';
import { ParticipationsTypesCollection } from 'src/app/core/collections/participations-types.collection';
import { UsersService } from 'src/app/core/http/users/users.service';

@Component({
  selector: 'app-how-to-upload-tickets',
  templateUrl: './how-to-upload-tickets.component.html',
  styleUrls: ['./how-to-upload-tickets.component.css']
})
export class HowToUploadTicketsComponent implements OnInit {

  participationsMethods = ParticipationsTypesCollection;

  participationMethod: number = null;

  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.usersService.getMyProfile()
      .subscribe((user) => this.participationMethod = user.data.include.locations.attributes.participation_type_id)
  }

}
