import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ELearningService {


  /**
   * Api resource
   *
   * @private
   * @type {string}
   * @memberof PostsService
   */
  private resource: string = 'e-learning/contents';

  /**
   * Creates an instance of PostsService.
   * 
   * @param {AuthorizationService} http
   * @memberof PostsService
   */
  constructor(
    private http: ApiService
  ) { }

  /**
   * List items.
   *
   * @returns
   * @memberof PostsService
   */
  findAll(filters = {}, sort: string = 'ElearningContents.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  /**
   * Find all featured posts
   *
   * @returns {Observable<any>}
   * @memberof PostsService
   */
  findAllFeatured(): Observable<any> {
    return this.findAll({
      'ElearningContents.featured': 1,
      'ElearningContents.status_id': 1,
    }, 'ElearningContents.created_at', 'desc', 0, 1000);
  }

  /**
   * Find all published posts.
   *
   * @returns {Observable<any>}
   * @memberof PostsService
   */
  findAllPublished(): Observable<any> {
    return this.findAll({
      'ElearningContents.status_id': 1
    }, 'ElearningContents.created_at', 'desc', 0, 1000);
  }

  /**
   * Find one post.
   *
   * @param {(string | number)} postId
   * @returns {Observable<any>}
   * @memberof PostsService
   */
  findOne(contentId: string | number, params: {}): Observable<any> {
    return this.http.get(`/${this.resource}/${contentId}`, this.http.parseParams(params));
  }

  /**
   * Training request.
   *
   * @param {number} contentId
   * @param {number} userId
   * @returns {Observable<any>}
   * @memberof ELearningService
   */
  requestTraining(contentId: number, userId: number): Observable<any> {
    return this.http.post(`/${this.resource}/${contentId}/request-training`, {
      userId: userId
    });
  }

  registerView(body): Observable<any> {
    return this.http.post(`/${this.resource}/view`, body);
  }

  /**
   * Register event.
   *
   * @param {string} eventType
   * @param {number} [contentId]
   * @param {number} [fileId]
   * @returns {Observable<any>}
   * @memberof ELearningService
   */
  registerEvent(eventType: string, contentId?: number|string, fileId?: number|string): Observable<any> {
    return this.http.post(`/e-learning/user-event`, {
      event_type: eventType,
      user_id: this.http.getUserId(),
      content_id: contentId,
      file_id: fileId
    });
  }
}
