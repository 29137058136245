import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { FeedListResource } from 'src/app/shared/interfaces/feed';
import { PhasesListResource } from 'src/app/shared/interfaces/phases.resource';
import { UserResource, UsersListResource } from 'src/app/shared/interfaces/users.resource';
import { filter } from 'rxjs/operators';



export interface IUser {
  type:    string;
  id:      number;
  attributes:    User;
  include: Include;
  meta:    IUserMeta;
}

export interface Include {
  locations:            Locations;
  users_points_wallets: UsersPointsWallet[];
}

export interface Locations {
  type:       string;
  id:         number;
  attributes: LocationsAttributes;
  meta:       LocationsMeta;
}

export interface LocationsAttributes {
  id:              number;
  code:            string;
  name:            string;
  status_id:       number;
  address:         string;
  postal_code:     number;
  country_id:      number;
  province_id:     number;
  city:            string;
  phone:           number;
  sales_objective: number;
  created_by:      number;
  created_at:      string;
  show_catalog:    number;
  instructions:    null;
  legal_base:      null;
}

export interface LocationsMeta {
  distributor_user_id:    number;
  total_users:            number;
  total_points:           number;
  total_available_points: number;
  total_sales:            number;
  total_units:            number;
  total_net_sales:        number;
}

export interface UsersPointsWallet {
  type:       string;
  id:         number;
  attributes: UsersPointsWalletAttributes;
}

export interface UsersPointsWalletAttributes {
  id:         number;
  user_id:    number;
  program_id: number;
  points:     number;
}

export interface IUserMeta {
  total_points:             number;
  total_sales:              number;
  points_by_phase:          PointsByPhase[];
  sales_manager_id:         number;
  sales_manager_name:       string;
  account_manager_id:       number;
  account_manager_name:     string;
  distributor_id:           number;
  distributor_name:         string;
  distributor_show_catalog: number;
  location_name:            string;
  total_new_notifications:  number;
}

export interface PointsByPhase {
  phase_id:               number;
  program_id:             number;
  phase_name:             string;
  phase_status_id:        number;
  phase_min_points:       number;
  phase_max_points:       number;
  user_points:            number;
  user_points_redeemable: number;
}

// export interface User {
//   id:                        number;
//   name:                      string;
//   surname:                   string;
//   id_card:                   string;
//   email:                     string;
//   role_id:                   number;
//   location_id:               number;
//   status_id:                 number;
//   points:                    number;
//   delivery_phone:            number;
//   delivery_address:          string;
//   delivery_postal_code:      number;
//   delivery_city:             string;
//   delivery_province_id:      number;
//   delivery_country_id:       number;
//   recover_hash:              string;
//   privacy_policy:            number;
//   commercial_communications: number;
//   push_notifications:        number;
//   created_at:                string;
//   password:                  string;
// }

export interface User {
  id: number,
  name: string,
  surname: string,
  email: string,
  password: string,
  role_id: number,
  status_id: number,
  points: number
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  /**
   * API resource.
   *
   * @private
   * @type {string}
   * @memberof UsersService
   */
  private resource: string = 'users';

  /**
   * Creates an instance of RolesService.
   * 
   * @param {AuthorizationService} http
   * @memberof RolesService
   */
  constructor(private http: AuthorizationService) { }

  /**
   * Find all items.
   *
   * @returns
   * @memberof RolesService
   */
  findAll(filters: Object = {}, sort: string = 'name', order: string = 'asc', pageNumber: number, pageSize: number = 10): Observable<UsersListResource> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=roles,status');
  }

  /**
   * Find a single item
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOne(id: number): Observable<UserResource> {
    return this.http.get(`/${this.resource}/${id}?include=locations,users_points_wallets,pictures`);
  }


  /**
   * Stores an item
   *
   * @param {Role} data
   * @returns
   * @memberof RolesService
   */
  store(data: any) {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Close the account.
   *
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  closeAccount(): Observable<any> {
    return this.http.delete(`/${this.resource}/${this.http.getUserId()}/account`);
  }

  /**
   * Get the profile of the current user in session.
   *
   * @returns
   * @memberof UsersService
   */
  getMyProfile(): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}?include=locations,users_push_subscriptions`);
  }

  /**
   * Get the active phases for the user in session.
   *
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  getMyPhases(filters = {}): Observable<PhasesListResource> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/phases`, this.http.parseParams(filters) + '&include=phases_questions');
  }

  /**
   * Get the grouped sales of the user in session.
   *
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  getMyGroupedSales(qs: string = ''): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/grouped-sales${qs}`);
  }

  /**
   * Get the grouped sales of the user in session.
   *
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  getMyProductsEvolution(phaseId: number): Observable<any> {
    return this.http.get(`/sales/my-products-evolution/phase/${phaseId}`);
  }

  /**
   * Get the user feed.
   *
   * @param {Object} [httpParams]
   * @returns {Observable<FeedListResource>}
   * @memberof UsersService
   */
  getMyFeed(pageNumber: number = 1, programId): Observable<FeedListResource> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/activity?page[number]=${pageNumber}&sort=-created_at&filter[UsersActivity.program_id]=${programId}`);
  }

  /**
   * Get active programs for user.
   */
  findMyActivePrograms(userId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}`, '?include=active_programs,locations');
  }
}
