import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cookies-config',
  templateUrl: './cookies-config.component.html',
  styleUrls: ['./cookies-config.component.scss']
})
export class CookiesConfigComponent implements OnInit {

  /**
   * Partial shown
   *
   * @type {string}
   * @memberof CookiesConfigComponent
   */
  partial: string = 'resume';

  /**
   * Form for cookies togglers.
   *
   * @type {FormGroup}
   * @memberof CookiesConfigComponent
   */
  cookiesForm: FormGroup = this.formBuilder.group({
    analytical: [true]
  });


  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CookiesConfigComponent>
  ) { }

  ngOnInit() { }

  /**
   * Show the given partial key.
   *
   * @param {string} key
   * @memberof CookiesConfigComponent
   */
  showPartial(key: string): void {
    this.partial = key;
  }

  /**
   * Get literal for a toggle from his value in the form control.
   *
   * @param {string} key
   * @returns {string}
   * @memberof CookiesConfigComponent
   */
  getLiteralForControl(key: string): string {
    return this.cookiesForm.get(key).value ? 'Activated' : 'Deactivated';
  }

  /**
   * Return the selected values.
   *
   * @memberof CookiesConfigComponent
   */
  save(): void {
    this.dialogRef.close(this.cookiesForm.value);
  }
}
