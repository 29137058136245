import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwPush } from '@angular/service-worker';
import { NotificationsService } from 'src/app/core/http/notifications/notifications.service';
import { HelperService } from 'src/app/services/helper.service';
import { UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-communications-dialog',
  templateUrl: './communications-dialog.component.html',
  styleUrls: ['./communications-dialog.component.scss']
})
export class CommunicationsDialogComponent {

  /**
   * From group.
   *
   * @type {FormGroup}
   * @memberof CommunicationsDialogComponent
   */
  form: FormGroup = this.formBuilder.group({
    id: [''],
    commercial_communications: [false, ''],
    push_notifications: [false, '']
  });


  constructor(
    public dialogRef: MatDialogRef<CommunicationsDialogComponent>,
    private userService: UsersService,
    private formBuilder: FormBuilder,
    public helper: HelperService,
    private swPush: SwPush,
    private notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form.patchValue(this.data.attributes);
  }

  close() {
    this.dialogRef.close();
  }

  acceptCommunications(): void {
    this.userService.store(this.form.value)
      .subscribe(r => {
        setTimeout(() => {
          this.close();
        }, 200);
      },
        error => {
          this.helper.errorSaving();
        });
  }

  acceptAll(): void {
    this.togglePushNotifications(true);
    this.form.get('commercial_communications').setValue(true);
    this.form.get('push_notifications').setValue(true);
    this.acceptCommunications();
  }

  /**
   * Toogle push notifications
   *
   * @param {*} state
   * @memberof CommunicationsDialogComponent
   */
  togglePushNotifications(state): void {
    if (this.swPush.isEnabled) {
      if (state) {

        this.swPush.requestSubscription({
          serverPublicKey: environment.vapidPublicKey
        })
          .then(sub => {
            this.notificationsService.subscribe(sub)
              .subscribe(() => { });
          })
          .catch(err => console.error("Could not subscribe to notifications", err));
      } else {
        this.swPush.unsubscribe().then(sub => {
          this.notificationsService.unsubscribe()
            .subscribe(() => { console.log('unsubscribed'); });
        });
      }
    }
  }

}
