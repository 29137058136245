import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LanguagesService } from 'src/app/services/calendar/languages.service';
import { LangOption } from 'src/app/shared/interfaces/lang-option';


@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {

  /**
   * The language selected by the user, from the cookie
   * or autodiscovered from the browser.
   *
   * @type {string}
   * @memberof LangSwitcherComponent
   */
  userLanguage: LangOption;

  /**
   * Creates an instance of LangSwitcherComponent.
   * 
   * @param {LanguagesService} languageService
   * @memberof LangSwitcherComponent
   */
  constructor(
    private languageService: LanguagesService
  ) { }

  /**
   * On view init.
   *
   * @memberof LangSwitcherComponent
   */
  ngOnInit() {
    this.userLanguage = this.languageService.currentLangOption;
    this.setLanguage(this.userLanguage)
  }

  /**
   * Get lang options.
   *
   * @returns {Array<LangOption>}
   * @memberof LangSwitcherComponent
   */
  getLangOptions(): Array<LangOption> {
    return this.languageService.getAvailableLanguages();
  }

  /**
   * Set the language selected by the user.
   *
   * @param {string} code
   * @memberof LangSwitcherComponent
   */
  setLanguage(langOption: LangOption): void {
    this.languageService.setLangOption(langOption, false)
    this.userLanguage = langOption;
  }
}
