import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { ELearningService } from 'src/app/core/http/e-learning/e-learning.service';

@Component({
  selector: 'app-featured-e-learnings',
  templateUrl: './featured-e-learnings.component.html',
  styleUrls: ['./featured-e-learnings.component.scss']
})
export class FeaturedELearningsComponent implements OnInit {

  /**
   * Options for swiper
   *
   * @type {SwiperOptions}
   * @memberof FeaturedELearningsComponent
   */
  config: SwiperOptions = {
    slidesPerView: (this.helper.mobileQuery.matches ? 1 : 2),
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 20
  };

  /**
   * Featured contents.
   *
   * @type {Array<any>}
   * @memberof FeaturedELearningsComponent
   */
  featuredContents: Array<any> = [];

  constructor(
    private contentsService: ELearningService,
    private helper: HelperService,
    private router: Router
  ) { }

  /**
   * On view init.
   *
   * @memberof FeaturedELearningsComponent
   */
  ngOnInit(): void {
    this.fetchContents();
  }

  /**
   * Fetch featured contents.
   *
   * @memberof FeaturedELearningsComponent
   */
  fetchContents(): void {
    this.contentsService.findAllFeatured()
      .subscribe((items: any) => {
        if (items.meta.count > 0) {
          this.featuredContents = items.data;
        }
      })
  }

  /**
   * Get item picture.
   *
   * @param {*} item
   * @returns {string}
   * @memberof FeaturedELearningsComponent
   */
  getItemPicture(item: any): string {
    if(item.meta.picture_url) {
      return `url('${item.meta.picture_url}')`;
    }
  }

  /**
   * Navigate to content
   *
   * @param {*} item
   * @memberof FeaturedELearningsComponent
   */
  navToContent(item: any): void {
    let route = `/f/e-learning/${item.id}`;
    this.router.navigate([route]);
  }

}
