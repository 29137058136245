<button mat-icon-button (click)="seekFrames(-5)">
  <mat-icon>skip_previous</mat-icon>
</button>

<button mat-icon-button (click)="seekFrames(-1)">
  <mat-icon>arrow_left</mat-icon>
</button>

<button mat-icon-button (click)="seekFrames(1)">
  <mat-icon>arrow_right</mat-icon>
</button>

<button mat-icon-button (click)="seekFrames(5)">
  <mat-icon>skip_next</mat-icon>
</button>
