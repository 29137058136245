export const localeConfig = {
  default: 'es',
  available: [
    {
      code: 'es',
      title: 'Spanish'
    },
    {
      code: 'pt',
      title: 'Portuguese'
    },
    {
      code: 'en',
      title: 'English'
    }
  ]
};