import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { PhasesListResource } from 'src/app/shared/interfaces/phases.resource';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  /**
   * Api resource
   *
   * @private
   * @type {string}
   * @memberof UsersService
   */
  private resource: string = 'users';

  /**
   * Status active.
   *
   * @type {number}
   * @memberof UsersService
   */
  public TYPE_ALL: number = -1;

  /**
   * Status active.
   *
   * @type {number}
   * @memberof UsersService
   */
  public TYPE_SALES: number = 1;

  /**
   * Status inactive
   *
   * @type {number}
   * @memberof UsersService
   */
  public TYPE_REQUESTS: number = 2;

  /**
   * Status pending.
   *
   * @type {number}
   * @memberof UsersService
   */
  public TYPE_OTHERS: number = 3;

  /**
   *Creates an instance of RolesService.
   * @param {AuthorizationService} http
   * @memberof RolesService
   */
  constructor(private http: ApiService, private apiService: ApiService) { }

  /**
   * Find all items.
   *
   * @returns
   * @memberof RolesService
   */
  findAll(filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=roles,status,users_types');
  }

  /**
   * Find a single item
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOne(id: number, include = '?include=locations,pictures,users_points_wallets'): Observable<any> {
    const _obs = this.http.get(`/${this.resource}/${id}${include}`);
    _obs.subscribe(res => {
      this.apiService.setUser(res);
    });
    return _obs;
  }

  getUserGroupedSales(userId: number, qs: string = ''): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}/grouped-sales${qs}`);
  }

  getUserGroupedPointsHistory(userId: number, qs: string = ''): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}/grouped-points-history${qs}`);
  }

    /**
   * Get the active phases for the user in session.
   *
   * @returns {Observable<any>}
   * @memberof UsersService
   */
     getMyPhases(filters = {}): Observable<PhasesListResource> {
      return this.http.get(`/${this.resource}/${this.http.getUserId()}/phases`, this.http.parseParams(filters) + '&include=phases_questions,phases_questions_of_user');
    }

  /**
   * Stores an item
   *
   * @param {Role} data
   * @returns
   * @memberof RolesService
   */
  store(data: any): Observable<any> {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete one or multiple items.
   * 
   * @param ids 
   */
  delete(ids): Observable<any> {
    return this.http.delete(`/${this.resource}/${ids.toString()}`);
  }

  /**
   * Get my profile.
   *
   * @param {string} [params={
   *     include: 'locations'
   *   }]
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  getMyProfile(params = {
    include: 'locations'
  }): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}${this.http.parseParams(params)}`);
  }

  /**
   * Get my grouped sales.
   *
   * @param {*} [params]
   * @returns
   * @memberof UsersService
   */
  getMyGroupedSales(params?) {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/grouped-sales${this.http.parseParams(params)}`);
  }

  /**
   * Find the user activity.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='created_at']
   * @param {string} [order='desc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns
   * @memberof UsersService
   */
  findMyActivity(filters: Object = {}, sort: string = 'created_at', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/activity`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=roles,status');
  }

  /**
   * Find the points history for user.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='created_at']
   * @param {string} [order='desc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns
   * @memberof UsersService
   */
  findMyPointsHistory(filters: Object = {}, sort: string = 'created_at', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/points-history`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=roles,status');
  }

  /**
   * CLose account.
   *
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  closeAccount(): Observable<any> {
    return this.http.delete(`/${this.resource}/${this.http.getUserId()}/account`);
  }


  findAllAddresses(userId: number, filters: Object = {}, sort: string = 'UsersAddresses.id', order: string = 'asc', pageNumber: number = 0, pageSize: number = 100): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}/addresses`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=provinces');
  }

  deleteAddress(userId: number, addressId: number): Observable<any> {
    return this.http.delete(`/${this.resource}/${userId}/addresses/${addressId}`);
  }

  storeAddress(userId, data: any): Observable<any> {
    if (data.id) {
      return this.http.put(`/${this.resource}/${userId}/addresses/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}/${userId}/addresses`, data);
    }
  }

  getMyProductsEvolution(phaseId: number): Observable<any> {
    return this.http.get(`/sales/my-products-evolution/phase/${phaseId}`);
  }

  /**
   * Get points types for users points history
   *
   * @returns
   * @memberof UsersService
   */
  getPointsTypesForFilter(): Array<any> {
    return [
      {
        id: this.TYPE_ALL,
        name: "HISTORY_POINTS_TYPE_ALL"
      },
      {
        id: this.TYPE_SALES,
        name: "HISTORY_POINTS_TYPE_SALES"
      },
      {
        id: this.TYPE_REQUESTS,
        name: "HISTORY_POINTS_TYPE_REQUESTS"
      }
    ];
  }
}
