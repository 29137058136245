import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar-public',
  templateUrl: './toolbar-public.component.html',
  styleUrls: ['./toolbar-public.component.css']
})
export class ToolbarPublicComponent implements OnInit {

  /**
   * Title for toolbar
   *
   * @type {string}
   * @memberof ToolbarPublicComponent
   */
  @Input() title: string;

  /**
   * Must show go back icon
   *
   * @type {boolean}
   * @memberof ToolbarPublicComponent
   */
  @Input() backIcon: boolean = false;

  /**
   * Must show social networks icons
   *
   * @type {boolean}
   * @memberof ToolbarPublicComponent
   */
  @Input() socialNetworks: boolean = true;

  /**
   * Creates an instance of ToolbarPublicComponent.
   * 
   * @param {Router} router
   * @memberof ToolbarPublicComponent
   */
  constructor(
    private router: Router
  ) { }

  /**
   * On view init
   *
   * @memberof ToolbarPublicComponent
   */
  ngOnInit(): void {
  }

  /**
   * Navigate to root path
   *
   * @memberof ToolbarPublicComponent
   */
  navToRoot(): void {
    this.router.navigate(['/'])
  }
}
