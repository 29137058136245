import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Fingerprint2 from 'fingerprintjs2';
import { storageConfig } from 'src/app/config/storage.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   *Creates an instance of AuthService.
   * @memberof AuthService
   */
  constructor(private http: HttpClient) { }


  /**
   * Check if user is authenticated.
   *
   * @returns {boolean}
   * @memberof AuthService
   */
  public isAuthenticated(): boolean {
    // get token from local storage
    let token = localStorage.getItem(storageConfig.authKey);
    // Check whether the token is expired and return
    // true or false
    if (token) {
      let data = JSON.parse(token);
      if (this.isAccessTokenActive() === false) {
        localStorage.removeItem(storageConfig.authKey);
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Check if access token is active.
   *
   * @returns
   * @memberof AuthService
   */
  isAccessTokenActive(): boolean {
    // get token from local storage
    let token = localStorage.getItem(storageConfig.authKey);

    // Check whether the token is expired and return
    // true or false
    if (token) {
      let data = JSON.parse(token);
      if (Math.floor(new Date().getTime() / 1000) > data.expires_at) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Get the acces token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getAccessToken(): string | null {
    let auth = localStorage.getItem(storageConfig.authKey);
    if (auth) {
      let data = JSON.parse(auth);
      return data.access_token;
    }

    return null;
  }

   /**
   * Get the current program id.
   */
    getProgramId(): string {
      return localStorage.getItem('programId');
    }

  /**
   * Get the refresh token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getRefreshToken(): string | null {
    let auth = localStorage.getItem(storageConfig.authKey);
    if (auth) {
      let data = JSON.parse(auth);
      return data.refresh_token;
    }

    return null;
  }


  /**
   * Store session in storage
   *
   * @param {*} session
   * @memberof AuthService
   */
  setSession(session): void {
    localStorage.setItem(storageConfig.authKey, JSON.stringify(session.data.attributes));
  }


  /**
   * Get devive fingerprint.
   */
  getFingerprint() {
    return localStorage.getItem(storageConfig.fingerprintKey);
  }

  /**
   * Set device fingerprint.
   */
  setFingerprint(): void {
    let fingerprint = this.getFingerprint();
    Fingerprint2.get(function (components) {
      if (!fingerprint) {
        let fingerprint = Fingerprint2.x64hash128(components.map(function (pair) {
          return pair.value
        }).join(), 31);
        localStorage.setItem(storageConfig.fingerprintKey, fingerprint);
      }
    });
  }


  /**
   * Clear the auth data from storage
   *
   * @memberof AuthService
   */
  clearAuthData() {
    localStorage.removeItem(storageConfig.authKey);
    localStorage.clear();
  }


  /**
  * Login.
  *
  * @param {*} params
  * @returns {Observable<any>}
  * @memberof LoginService
  */
  login(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login`, params);
  }

  refreshToken() {
    return this.http.post(`${environment.apiUrl}/auth/refresh`, {
      refresh_token: this.getRefreshToken()
    });
  }


  /**
   * Logout service.
   *
   * @returns
   * @memberof AuthService
   */
  logout() {
    // Get the access
    let accessToken = this.getAccessToken();
    // Clear the auth data
    this.clearAuthData();

    return this.http.post(`${environment.apiUrl}/auth/logout`, {
      access_token: accessToken
    });
  }

  register(data) {
    return this.http.post(`${environment.apiUrl}/auth/register`, data);
  }

  rememberPassword(data) {
    return this.http.post(`${environment.apiUrl}/auth/remember-password`, data);
  }

  restorePassword(data) {
    return this.http.post(`${environment.apiUrl}/auth/restore-password`, data);
  }

  /**
   * Check the uniqueness of an email address.
   * @param emailAddress Email address
   */
  checkEmailUniqueness(emailAddress: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/auth/check-email?email=${emailAddress}`);
  }

  checkEmailLocationRestriction(emailAddress, locationId) {
    return this.http.get(`${environment.apiUrl}/auth/check-location-email?email=${emailAddress}&location_id=${locationId}`);
  }

  checkRecoverHash(hash) {
    return this.http.get(`${environment.apiUrl}/auth/check-recover-hash?hash=${hash}`);
  }

  /**
   * Checks the registration hash validity and return user data.
   * 
   * @param hash Registration hash
   */
  checkRegistrationHash(hash: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/auth/check-registration-hash?hash=${hash}`);
  }

  preRegister(data: any) {
    return this.http.post(`${environment.apiUrl}/auth/pre-register`, data);
  }

  /**
   * Complete registration process.
   * 
   * @param body User data.
   */
  completeRegister(body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/complete-registration`, body);
  }

  acceptPolicyUpdate(userId) {
    return this.http.post(`${environment.apiUrl}/auth/accept-policy`, {
      user_id: userId
    });
  }

  unsubscribe(hash) {
    return this.http.get(`${environment.apiUrl}/auth/${hash}/unsubscribe`);
  }
}
