import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatexPipe } from './pipes/datex.pipe';
import { UniqueEmailDirective } from './directives/unique-email.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PreviewImageComponent } from './components/preview-image/preview-image.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { CookiesModalComponent, CookiesModalContent } from './components/cookies-modal/cookies-modal.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CookiesConfigComponent } from './components/cookies-config/cookies-config.component';
import { RrssComponent } from './components/rrss/rrss.component';
import { ChallengesListComponent } from './components/challenges-list/challenges-list.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FeaturedPostsComponent } from './components/featured-posts/featured-posts.component';
import { FeaturedELearningsComponent } from './components/featured-e-learnings/featured-e-learnings.component';
import { MatVideoModule } from 'ng-mat-video';
import { ToolbarPublicComponent } from './components/toolbar-public/toolbar-public.component';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { GlobalRankingComponent } from './components/global-ranking/global-ranking.component';
import { RouterModule } from '@angular/router';
import { TelInputComponent } from './components/tel-input/tel-input.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { HowToUploadTicketsComponent } from './components/how-to-upload-tickets/how-to-upload-tickets.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SmdFabSpeedDialModule } from 'angular-speed-dial';
import { ProgramSelectionComponent } from '../components/program-selection/program-selection.component';
import { LangSwitcherComponent } from '../components/lang-switcher/lang-switcher.component';
import { CommunicationsDialogComponent } from './components/communications-dialog/communications-dialog.component';
import { TutorialDialogComponent } from './components/tutorial-dialog/tutorial-dialog.component';

@NgModule({
  declarations: [
    DatePickerComponent,
    ConfirmDialogComponent,
    PreviewImageComponent,
    DatexPipe,
    UniqueEmailDirective,
    LoadingComponent,
    InfoDialogComponent,
    CookiesModalComponent,
    CookiesModalContent,
    CookiesConfigComponent,
    RrssComponent,
    ChallengesListComponent,
    SafeHtmlPipe,
    SafePipe,
    FeaturedPostsComponent,
    FeaturedELearningsComponent,
    ToolbarPublicComponent,
    CommonHeaderComponent,
    GlobalRankingComponent,
    TelInputComponent,
    StarRatingComponent,
    HowToUploadTicketsComponent,
    ProgramSelectionComponent,
    LangSwitcherComponent,
    CommunicationsDialogComponent,
    TutorialDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxUsefulSwiperModule,
  ],
  exports: [
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ConfirmDialogComponent,
    PreviewImageComponent,
    LoadingComponent,
    InfoDialogComponent,
    ProgramSelectionComponent,
    DatexPipe,
    SafeHtmlPipe,
    FileUploadModule,
    UniqueEmailDirective,
    CookiesModalComponent,
    CookiesConfigComponent,
    RrssComponent,
    ChallengesListComponent,
    SafePipe,
    FeaturedPostsComponent,
    FeaturedELearningsComponent,
    MatVideoModule,
    ToolbarPublicComponent,
    CommonHeaderComponent,
    GlobalRankingComponent,
    TelInputComponent,
    StarRatingComponent,
    HowToUploadTicketsComponent,
    SmdFabSpeedDialModule,
    LangSwitcherComponent,
    CommunicationsDialogComponent,
    TutorialDialogComponent
  ]
})

export class SharedModule { }
