import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  /**
   *Creates an instance of AuthorizationService.
   * @param {HttpClient} http
   * @memberof AuthorizationService
   */
  constructor(
    private http: HttpClient
  ) { }


  /**
   * Get headers for auth request
   *
   * @returns
   * @memberof AuthorizationService
   */
  getHttpOptions() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getAccessToken()
    })
    if(!environment.production){
      headers.set('localhost-mode', 'http://localhost:4200')
    }
    return {
      headers
    };
  }

  /**
   * Get access token.
   *
   * @returns {string}
   * @memberof AuthorizationService
   */
  getAccessToken(): string {
    let session = JSON.parse(localStorage.getItem('_cauth'));
    if (session && session.access_token) {
      return session.access_token;
    }

    return '';
  }

  /**
   * Get user id.
   *
   * @returns {string}
   * @memberof AuthorizationService
   */
  getUserId(): number {
    let session = JSON.parse(localStorage.getItem('_cluser'));
    if (session && session.data.id) {
      return session.data.id;
    }

    return;
  }


  /**
   * Set user in local storage.
   *
   * @param {*} userObject
   * @param {string} context
   * @memberof AuthorizationService
   */
  setUser(userObject: any, context: string) {
    localStorage.setItem(`_u${context}`, JSON.stringify(userObject));
  }


  /**
   * Get user from local storage.
   *
   * @param {string} context
   * @returns
   * @memberof AuthorizationService
   */
  getUser(context: string) {
    let user = localStorage.getItem(`_u${context}`);

    if (user) {
      return JSON.parse(user);
    }

    return {};
  }


  parseParams(params: {} = {}) {
    let p = new HttpParams();

    if (params) {
      
      for (let key in params) {
        if (typeof params[key] == 'object') {
          for (let skey in params[key]) {
            p = p.set(`${key}[${skey}]`, params[key][skey]);
          }
        } else {
          p = p.set(key, params[key]);
        }
      }

      return '?' + p.toString();
    }

    return '';
  }

  buildListQueryString(filters: Object = {}, sort: string, order: string, pageNumber: number, pageSize: number): string {
    return this.parseParams({
      sort: (order == 'desc' ? '-' : '') + sort,
      page: {
        number: pageNumber + 1,
        size: pageSize
      },
      filter: filters
    });
  }


  /**
   * Perform an authenticated request via GET.
   *
   * @param {string} uri
   * @param {string} [qs='']
   * @returns
   * @memberof AuthorizationService
   */
  get(uri: string, qs: string = ''): Observable<any> {
    return this.http.get(environment.apiUrl + uri + qs, this.getHttpOptions());
  }


  /**
   * Perform an authenticated request via POST.
   *
   * @param {string} uri
   * @param {*} body
   * @returns
   * @memberof AuthorizationService
   */
  post(uri: string, body): Observable<any> {
    return this.http.post(environment.apiUrl + uri, body, this.getHttpOptions());
  }


  /**
   * Perform an authenticated request via PUT.
   *
   * @param {string} uri
   * @param {*} body
   * @returns
   * @memberof AuthorizationService
   */
  put(uri: string, body): Observable<any> {
    return this.http.put(environment.apiUrl + uri, body, this.getHttpOptions());
  }


  /**
   * Perform an authenticated request via PUT.
   *
   * @param {string} uri
   * @param {*} body
   * @returns
   * @memberof AuthorizationService
   */
  delete(uri: string): Observable<any> {
    return this.http.delete(environment.apiUrl + uri, this.getHttpOptions());
  }
}
