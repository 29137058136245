import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { GaService } from '../../services/ga.service';
import { CookiesConfigComponent } from '../cookies-config/cookies-config.component';
import { cookiesConfig } from 'src/app/config/cookies.config';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html'
})
export class CookiesModalComponent implements OnInit {

  /**
   * Indicates if cookies were accepted.
   *
   * @type {boolean}
   * @memberof CookiesModalComponent
   */
  cookiesAccepted: boolean = false;

  /**
   * Creates an instance of CookiesModalComponent.
   * 
   * @param {CookieService} cookieService
   * @memberof CookiesModalComponent
   */
  constructor(
    private cookieService: CookieService,
    private bottomSheet: MatBottomSheet,
    private gaService: GaService
  ) { }

  /**
   * On view init.
   *
   * @memberof CookiesModalComponent
   */
  ngOnInit() {
    let cookiesAcceptance = this.cookieService.get(cookiesConfig.gdpr);
    if (cookiesAcceptance) {
      this.cookiesAccepted = true;

      if (cookiesAcceptance == '1') {
        this.insertTagManager();
      }

    } else {
      this.bottomSheet.open(CookiesModalContent, {
        disableClose: true,
        panelClass: 'cookies-modal'
      });
    }
  }

  /**
   * Insert tag manager.
   *
   * @memberof CookiesModalComponent
   */
  insertTagManager(): void {
    this.gaService.addTags();
  }
}

@Component({
  selector: 'cookies-modal-content',
  templateUrl: 'cookies-modal-content.html',
  styleUrls: ['./cookies-modal.content.scss']
})
export class CookiesModalContent {

  /**
   * Link params.
   *
   * @memberof CookiesModalContent
   */
  linksParams = {
    cookiePolicyLink: '/policies/cookies-policy'
  };

  /**
   * Creates an instance of CookiesModalContent.
   * 
   * @param {MatBottomSheetRef<CookiesModalContent>} _bottomSheetRef
   * @param {CookieService} cookieService
   * @memberof CookiesModalContent
   */
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<CookiesModalContent>,
    private cookieService: CookieService,
    private gaService: GaService,
    private dialog: MatDialog
  ) { }

  /**
   * Close the cookies modal.
   *
   * @memberof CookiesModalContent
   */
  close(): void {
    this._bottomSheetRef.dismiss();
  }

  /**
   * Accept cookies
   *
   * @memberof CookiesModalComponent
   */
  acceptAll(): void {

    this.cookieService.set(cookiesConfig.gdpr, '1', this.getCookieExpirationDate(), null, null, environment.production, 'Strict');
    this.insertTagManager();
    this.close();
  }

  /**
   * Get cookie expiration date
   *
   * @returns {Date}
   * @memberof CookiesModalContent
   */
  getCookieExpirationDate(): Date {
    let expiration = new Date();
    expiration.setMonth(expiration.getMonth() + 3);
    return expiration;
  }

  /**
   * Reject cookies.
   *
   * @memberof CookiesModalContent
   */
  reject(): void {
    this.cookieService.set(cookiesConfig.gdpr, '0', this.getCookieExpirationDate(), null, null, environment.production, "Strict");
    this.close();
  }

  /**
   * Open the configuration modal
   *
   * @memberof CookiesModalContent
   */
  configure() {
    this.dialog.open(CookiesConfigComponent, {
      panelClass: 'cookies-config-modal'
    })
      .afterClosed()
      .subscribe((config: any) => {
        if (config !== undefined) {
          if (config.analytical) {
            this.acceptAll();
          } else {
            this.reject();
          }
        }
      })
  }

  /**
   * Insert tag manager.
   *
   * @memberof CookiesModalComponent
   */
  insertTagManager(): void {
    this.gaService.addTags();
  }
}