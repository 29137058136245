import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.css']
})
export class CommonHeaderComponent implements OnInit {

  /**
   * Title.
   *
   * @type {string}
   * @memberof CommonHeaderComponent
   */
  @Input() title: string;
  background = '/assets/img/bgs/common-header.jpg'
  /**
   * Subtitle.
   *
   * @type {string}
   * @memberof CommonHeaderComponent
   */
  @Input() subtitle: string;

  constructor(
    public helper: HelperService,
    private router: Router
  ) {}

  /**
   * On view init.
   *
   * @memberof CommonHeaderComponent
   */
  ngOnInit(): void {
    let route = this.router.url.split('/');
    this.background = route[route.length -1] === 'challenges' ? '/assets/img/bgs/challenges-bg.jpg' : route[route.length -1] === 'catalog' ? '/assets/img/bgs/catalog-bg.jpg' : '/assets/img/bgs/common-header.jpg';
  }

}
