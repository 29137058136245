import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwPush } from '@angular/service-worker';
import { NotificationsService } from 'src/app/core/http/notifications/notifications.service';
import { ProgramsService } from 'src/app/core/http/programs/programs.service';

import { AuthService } from 'src/app/services/auth.service';
import { LanguagesService } from 'src/app/services/calendar/languages.service';
import { HelperService } from 'src/app/services/helper.service';

import { IUser, UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-program-selection',
  templateUrl: './program-selection.component.html',
  styleUrls: ['./program-selection.component.scss']
})
export class ProgramSelectionComponent implements OnInit {

  /**
   * From group.
   *
   * @type {FormGroup}
   * @memberof AcceleratorDetailsComponent
   */
  form: FormGroup = this.formBuilder.group({
    id: [''],
    program_policy: [false, Validators.required],
    commercial_communications: [false, ''],
    push_notifications: [false, '']
  });

  /**
   * Available programs.
   */
  programs: Array<any> = [];

  /**
   * Display mode.
   */
  displayMode: 'program' | 'policy' = 'program';

  policy: any = null;

  selectedProgram: any;

  location: any;

  constructor(
    public dialogRef: MatDialogRef<ProgramSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public userId: number,
    private userService: UsersService,
    private authService: AuthService,
    private languageService: LanguagesService,
    private programService: ProgramsService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private swPush: SwPush,
    public helper: HelperService
  ) { }

  ngOnInit() {
    this.userService.findMyActivePrograms(this.userId)
      .subscribe(user => {
        this.location = user.data.include.locations
        this.programs = user.data.include.active_programs;
        this.form.patchValue(user.data.attributes);
        if (this.programs.length == 1) {
          this.selectProgram(this.programs[0]);
        }
      });
  }

  selectProgram(program): void {
    this.selectedProgram = program;
    if (!program.meta.policy_accepted) {
      this.policy = this.location.attributes.legal_base;
      if (this.policy == null || this.policy == '') {
        this.policy = program.attributes[`bases_${this.languageService.currentLangOption.code}`];
      }
      this.displayMode = 'policy';
    } else {
      this.dialogRef.close(program.id);
    }
  }

  isCurrent(program): boolean {
    return program.id == this.authService.getProgramId();
  }

  acceptBases(): void {
    this.userService.store(this.form.value)
      .subscribe(r => {
        setTimeout(() => {
          this.programService.legalBasesAcceptance(this.selectedProgram.id, this.userId)
            .subscribe(() => this.dialogRef.close(this.selectedProgram.id));
        }, 200);
      },
        error => {
          this.helper.errorSaving();
        });
  }

  /**
   * Toogle push notifications
   *
   * @param {*} state
   * @memberof ProgramSelectionComponent
   */
  togglePushNotifications(state): void {
    if (this.swPush.isEnabled) {
      if (state) {

        this.swPush.requestSubscription({
          serverPublicKey: environment.vapidPublicKey
        })
          .then(sub => {
            this.notificationsService.subscribe(sub)
              .subscribe(() => { });
          })
          .catch(err => console.error("Could not subscribe to notifications", err));
      } else {
        this.swPush.unsubscribe().then(sub => {
          this.notificationsService.unsubscribe()
            .subscribe(() => { console.log('unsubscribed'); });
        });
      }
    }
  }
}
