import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './tutorial-dialog.component.html',
  styleUrls: ['./tutorial-dialog.component.scss']
})
export class TutorialDialogComponent {

  /**
   *Creates an instance of TutorialDialogComponent.
   * @param {MatDialogRef<TutorialDialogComponent>} dialogRef
   * @memberof TutorialDialogComponent
   */
  constructor(
    public dialogRef: MatDialogRef<TutorialDialogComponent>
  ) { }


  /**
   * Close the dialog and send the decision to the opener.
   *
   * @memberof TutorialDialogComponent
   */
  close() {
    this.dialogRef.close();
  }
}
