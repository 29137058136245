import { Directive, forwardRef, Injectable } from '@angular/core';
import {
  AsyncValidator,
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  ValidationErrors
} from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/authetication/auth.service';

@Injectable({ providedIn: 'root' })
export class UniqueEmailValidator implements AsyncValidator {
  
  constructor(private authService: AuthService) { }

  validate(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (ctrl.value) {
      return this.authService.checkEmailUniqueness(ctrl.value).pipe(
        map(res => (res['data']['is_taken'] ? { uniqueEmail: true } : null)),
        catchError(() => null)
      );
    } else {
      return new Observable();
    }
  }
}

@Directive({
  selector: '[appUniqueEmail]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => UniqueEmailValidator),
      multi: true
    }
  ]
})
export class UniqueEmailDirective {

  constructor(
    private validator: UniqueEmailValidator
  ) { }

  validate(control: AbstractControl) {
    if (control.value) {
      this.validator.validate(control);
    }
  }
}
