
import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { cookiesConfig } from 'src/app/config/cookies.config';


@Injectable({
  providedIn: 'root'
})
export class GaService {

  /**
   * Renderer.
   *
   * @private
   * @type {Renderer2}
   * @memberof GaService
   */
  private renderer: Renderer2

  /**
   * Creates an instance of GaService.
   * 
   * @param {RendererFactory2} rendererFactory
   * @param {*} document
   * @memberof GaService
   */
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Add tags to document.
   *
   * @memberof GaService
   */
  addTags(): void {

    if (cookiesConfig.tagManagerId) {
      // Tag manager
      let script = this.renderer.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${cookiesConfig.tagManagerId}`;
      script.text = ``;
      this.renderer.appendChild(this.document.head, script);

      let script3 = this.renderer.createElement('script');
      script3.text = `(function (w, d, s, l, i) {w[l] = w[l] || []; w[l].push({'gtm.start':new Date().getTime(), event: 'gtm.js'}); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', '${cookiesConfig.tagManagerId}');`;
      this.renderer.appendChild(this.document.body, script3);
    }
  }

  addUserIdTag(user = null): void {
    const userID = user ? user : '0';

    if (document.getElementById('tagUserId')) {
      document.getElementById('tagUserId').remove();
    }

    const script = this.renderer.createElement('script');
    script.id = 'tagUserId';
    script.text = `window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'login',
        'userId' : '${userID}'
      })`;
    this.renderer.appendChild(this.document.body, script);
  }
}
