import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http'
import { Observable, throwError as observableThrowError, BehaviorSubject } from 'rxjs';

import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../authetication/auth.service';
import { storageConfig } from 'src/app/config/storage.config';
import { CookieService } from 'ngx-cookie-service';


@Injectable()

export class RequestInterceptorService implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private cookiesService: CookieService,
    private router: Router
  ) { }

  /**
   * Get language code.
   *
   * @returns {string}
   * @memberof RequestInterceptorService
   */
   getLanguageCode(): string {
    return this.cookiesService.get('_lng');
  }

  addAccessToken(req: HttpRequest<any>): HttpRequest<any> {
    let headers = {
      Authorization: 'Bearer ' + this.authService.getAccessToken(),
      'Accept-Language': this.getLanguageCode()
    };

    let programId = localStorage.getItem('programId');
    if(programId){
      headers['Client-Program-Id'] = localStorage.getItem('programId');
    }
    
    return req.clone({
      setHeaders: headers
    });
  }

  handleExpired(req: HttpRequest<any>, next: HttpHandler) {
    
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((session) => {
          
          if (session['data']['access_token']) {
            localStorage.setItem(storageConfig.authKey, JSON.stringify(session['data']));
            this.tokenSubject.next(session['data']['access_token']);
            return next.handle(this.addAccessToken(req));
          }

          // If we don't get a new token, we are in trouble so logout.
          this.authService.logout();
          // this.router.navigate(['/']);
          window.location.href = '/';
          return observableThrowError('');
        }),
        catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          this.authService.logout();
          // this.router.navigate(['/']);
          window.location.href = '/';
          return observableThrowError('');
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));

    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAccessToken(req));
        }));
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isAuthRequest = request.headers.get('Authorization');

    if (request.url.startsWith(environment.apiUrl) && isAuthRequest) {
      return next.handle(this.addAccessToken(request)).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
              
                if (error.error.errors[0].code == 'expired') {
                  return this.handleExpired(request, next);
                } else if(! this.authService.isAccessTokenActive()) {
                  // this.router.navigate(['/']);
                  window.location.href = '/';
                }

                return observableThrowError(error);
              default:
                return observableThrowError(error);
            }
          } else {
            return observableThrowError(error);
          }

        })
      )
    } else {
      return next.handle(request);
    }
  }
}