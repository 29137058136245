import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent implements OnInit {
  @Input() rating: number = 0;
  @Input() starCount: number = 6;
  @Output() ratingUpdated = new EventEmitter();

  public ratingArr = [];
  ratingHover: number = 0;

  constructor() {
  }

  ngOnInit() {
    this.ratingHover = this.rating;
    for (let index = 1; index <= this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  /**
   * On click.
   *
   * @param {number} rating
   * @returns
   * @memberof StarRatingComponent
   */
  onClick(rating: number) {
    this.ratingUpdated.emit(rating);
    this.rating = rating;
    return false;
  }

  showIcon(index: number) {
    if (this.ratingHover >= index) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  getColor(index: number) {
    if (this.ratingHover >= index) {
      return 'primary';
    } else {
      return null;
    }
  }

  mouseOver(index: number) {
    this.ratingHover = index;
  }

  mouseOut() {
    this.ratingHover = this.rating;
  }
}
