<div class="mat-slider-wrapper" #sliderWrapper id="test">
  <div class="mat-slider-track-wrapper">
    <svg width="100%" height="2" focusable="false" class="mat-slider-progress-background">
      <defs>
        <pattern [id]="sliderprogressbarId" x="2.5" y="0" width="5" height="2.5" patternUnits="userSpaceOnUse">
          <circle cx="1.25" cy="1.25" r="1.25" />
        </pattern>
      </defs>
      <rect [attr.fill]="'url(#' + sliderprogressbarId + ')'" width="100%" height="100%" />
    </svg>
    <div class="mat-slider-track-fill mat-slider-track-buffer" [ngStyle]="_trackBufferStyles"></div>
    <div class="mat-slider-track-fill" [ngStyle]="_trackFillStyles"></div>
  </div>
  <div class="mat-slider-ticks-container" [ngStyle]="_ticksContainerStyles">
    <div class="mat-slider-ticks" [ngStyle]="_ticksStyles"></div>
  </div>
  <div class="mat-slider-thumb-container" [ngStyle]="_thumbContainerStyles">
    <div class="mat-slider-focus-ring"></div>
    <div class="mat-slider-thumb"></div>
    <div class="mat-slider-thumb-label">
      <span class="mat-slider-thumb-label-text">{{ displayValue }}</span>
    </div>
  </div>
</div>
