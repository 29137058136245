import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { LoadingComponent } from '../components/loading/loading.component';
// import { MatDialog, MatSnackBar } from '@angular/material';
import { InfoDialogComponent } from '../components/info-dialog/info-dialog.component';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  // Observable string sources
  private emitChangeSource = new Subject<any>();
  
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();

  // Service message commands
  emitChange(change: any) {
      this.emitChangeSource.next(change);
  }

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    // changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1025px)');
    // this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  destroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }


  openLoading(text: string = 'Loading') {
    this.translate.get([
      text
    ]).subscribe((res: string) => {
      this.dialog.open(LoadingComponent, {
        id: 'loading',
        disableClose: true,
        data: {
          content: res[text]
        }
      });
    });
  }

  closeLoading() {
    this.dialog.getDialogById('loading').close();
  }

  errorSaving() {
    this.dialog.closeAll();
    let text = 'ERROR_SAVING';
    this.translate.get([
      text
    ]).subscribe((res: string) => {
      this.dialog.open(InfoDialogComponent, {
        id: 'error-saving',
        disableClose: true,
        data: {
          content: res[text]
        }
      });
    });
  }

  showSnackBar(text: string = 'Saved successfully') {
    this.translate.get([
      text
    ]).subscribe((res: string) => {
      this.snackBar.open(res[text], 'Ok', {
        duration: 2000,
      });
    });
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
