import { Injectable } from '@angular/core';
import { AuthService } from '../../authetication/auth.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  /**
   * API resource.
   *
   * @private
   * @type {string}
   * @memberof NotificationsService
   */
  private resource: string = 'notifications';

  /**
   * Creates an instance of NotificationsService.
   * 
   * @param {ApiService} http
   * @param {AuthService} authService
   * @memberof NotificationsService
   */
  constructor(
    private http: ApiService,
    private authService: AuthService
  ) { }

  /**
   * Get inbox
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='NotificationsInbox.created_at']
   * @param {string} [order='desc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns
   * @memberof NotificationsService
   */
  getInbox(filters: Object = {}, sort: string = 'NotificationsInbox.created_at', order: string = 'desc', pageNumber: number= 0, pageSize: number = 10) {
    return this.http.get(`/${this.resource}/inbox`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  subscribe(subscription) {
    return this.http.post(`/${this.resource}/subscribe`, {
      subscription: JSON.stringify(subscription),
      device_uid: this.authService.getFingerprint()
    });
  }

  unsubscribe() {
    return this.http.post(`/${this.resource}/unsubscribe`, {
      device_uid: this.authService.getFingerprint()
    });
  }

  setNotificationAsReaded(notificationId) {
    return this.http.post(`/${this.resource}/inbox/${notificationId}/readed`, {});
  }

  countNewNotifications() {
    return this.http.get(`/${this.resource}/inbox/new-count`);
  }
}
