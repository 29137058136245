import { Component, OnInit, Input } from '@angular/core';
import { ProgramsService } from 'src/app/core/http/programs/programs.service';
import { Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { StatusService } from 'src/app/core/http/status/status.service';
import { SwiperOptions } from 'swiper';
import { HelperService } from '../../services/helper.service';
import { LanguagesService } from '../../../services/calendar/languages.service';
const moment = _moment;

export const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-challenges-list',
  templateUrl: './challenges-list.component.html',
  styleUrls: ['./challenges-list.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class ChallengesListComponent implements OnInit {

  /**
   * Phase type
   *
   * @type {('active' | 'all')}
   * @memberof ChallengesListComponent
   */
  @Input() phaseType: 'active' | 'all' = 'active';

  /**
   * Active phases.
   *
   * @type {Array<any>}
   * @memberof ChallengesListComponent
   */
  activeProgram: any = null;

  /**
   * List of phases.
   *
   * @type {Array<any>}
   * @memberof ChallengesListComponent
   */
  phasesList: Array<any> = []

  languageCode = 'es';
  /**
   * Days to consider a challenge as new.
   *
   * @type {number}
   * @memberof ChallengesListComponent
   */
  daysToSetChallengeAsNew: number = 5;

  @Input() displayMode: string = 'list';

  /**
   * Main campaign
   */
  mainCampaign: any;


  config: SwiperOptions = {
    slidesPerView: (this.helper.mobileQuery.matches ? 1 : 3),
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30
  };

  /**
   * Creates an instance of ChallengesListComponent.
   *
   * @param {ProgramsService} programsService
   * @param {Router} router
   * @memberof ChallengesListComponent
   */
  constructor(
    private programsService: ProgramsService,
    private statusService: StatusService,
    private router: Router,
    private helper: HelperService,
    private languagesService:LanguagesService
  ) { }

  /**
   * On view init.
   *
   * @memberof ChallengesListComponent
   */
  ngOnInit(): void {
    this.fetchActiveProgram();
    this.languageCard(localStorage.getItem('_lng'))
    this.languagesService.languageChanged.subscribe(language=>{
      this.languageCard(language.code)

    })

  }

  /**
   * Fetch active phases.
   *
   * @memberof ChallengesListComponent
   */
  fetchActiveProgram(): void {
    this.programsService.findActiveProgramAndActivePhases()
      .subscribe((program: any) => {
        this.activeProgram = program.data;
        switch (this.phaseType) {
          case 'all':
            this.phasesList = program.data.include.phases_for_user.filter(phase=>phase.attributes.program_id === Number(localStorage.getItem('programId')));
            if(this.phasesList){
              this.phasesList.forEach(phase => {
                for (const key in phase.meta.phase_translations) {
                  switch (phase.meta.phase_translations[key].lang) {
                    case 2:
                      phase.attributes.title_pt = phase.meta.phase_translations[key].name;
                      phase.attributes.pictureUrl_pt = phase.meta.phase_translations[key].pictureUrl;
                      break;
                    case 4:
                      phase.attributes.title_en = phase.meta.phase_translations[key].name;
                      phase.attributes.pictureUrl_en = phase.meta.phase_translations[key].pictureUrl;
                      break;

                    default:
                      break;
                  }

                }
                return
              });
            }
            break;
          case 'active':
          default:
            this.phasesList = program.data.include.active_phases_for_user.filter(phase=>phase.attributes.program_id === Number(localStorage.getItem('programId')));;

            break;
        }

        if (this.phasesList && this.phasesList.length > 0) {
          this.phasesList.forEach(element => {
            if (element.attributes.is_main) {
              this.mainCampaign = element;
            }
          });
        }
      });

  }

  languageCard(language){
    switch (language) {
      case 'pt':
        this.languageCode = 'pt'
        break;
      case 'en':
        this.languageCode = 'en'
        break;
      case 'es':
        this.languageCode = 'es'
        break;
      default:
        this.languageCode = 'es'
        break;
    }
  }

  /**
   * Get phase picture background.
   *
   * @param {*} item
   * @returns {(string | null)}
   * @memberof ChallengesListComponent
   */
  getPhasePictureBg(item: any): string | null {
    /*
    return this.languageCode ==='pt' && item.attributes.pictureUrl_pt? `url(${item.attributes.pictureUrl_pt})` :
    this.languageCode === 'en' && item.attributes.pictureUrl_en?  `url(${item.attributes.pictureUrl_en})` :
    this.languageCode === 'es' &&  item.meta.picture_url && item.meta.picture_url !== '' ? `url(${item.meta.picture_url})` : null
    */

    return item.meta.picture_url && item.meta.picture_url !== '' ? `url(${item.meta.picture_url})` : null;
  }

  /**
   * Navigate to given challenge
   *
   * @param {*} item
   * @memberof ChallengesListComponent
   */
  navToChallenge(item: any): void {
    let route = `/f/challenges/${item.id}`;
    this.router.navigate([route])
  }

  /**
   * Indicates if the challenge is new.
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isNew(item: any): boolean {
    let now = moment();
    let startsAt = moment(item.attributes.starts_at);

    return now.diff(startsAt, 'days') <= this.daysToSetChallengeAsNew;
  }

  /**
   * Indicates if item is active.
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isActive(item: any): boolean {
    return item.attributes.status_id == this.statusService.STATUS_ACTIVE;
  }

  /**
   * Indicates if challenge is completed by user.
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isCompleted(item: any): boolean {
    return item.meta.test_completed_by_user == 1;
  }

  isComingSoon(item: any): boolean {
    return moment() < moment(`${item.attributes.starts_at} 00:00:00`);
  }

  reachedPointsLimit(item: any): boolean {
    return item.meta.user_points_limit;
  }


  /**
   *  Check if limit by participations is reached
   *
   * @param {*} item
   * @return {*}  {boolean}
   */
  reachedParticipatingLimit(item: any): boolean {
    return item.meta.participation_max_reached;
  }

  hasSales(item: any): boolean {
    return item.meta.total_sales_by_user > 0;
  }

  /**
   * Indicates if navigation controls are visible.
   * They are visible when there at least 1 no-main campaign
   */
  areControlsVisible(): boolean {
    let visible: boolean = false;

    this.phasesList.forEach((element) => {
      if (!element.attributes.is_main) {
        visible = true;
      }
    });

    return visible;
  }

  /**
   * Indicates if item is inactive
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isInactive(item: any): boolean {
    return moment(`${item.attributes.sales_end_at} 23:59:59`) < moment();
  }

  /**
   * Indicates if item can upload ticket
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
   inUploadDate(item: any): boolean {
    return moment(`${item.attributes.ends_at} 23:59:59`) < moment() && moment(`${item.attributes.sales_end_at} 23:59:59`) > moment();
  }
}
